@import url(//netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css);

.articles-container {
	min-height: calc( 100vh ) ;
	background-color: white ;
	padding: 0px !important;
}

.articles-view-jumbotron {
	padding: 4rem 5% !important ;
}

.articles-view-container {
	padding: 1rem 5% !important ;
}

.articles-list-container {
	padding: 0px !important ;
}

.article-list-group {
	width: 100% ;
}

img {
  border: 1px solid #ddd !important ;
  border-radius: 4px;
	padding: 5px;
}
